/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { StoreProvider, Tokens, TokensProvider } from '@mcn-platform/models';
import { PortalRouteMapper } from '@mcn-platform/portal-lib';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PortalCss from './PortalCss';

const ClientPortalPage = ({
  store,
  basepath,
}:any) => (
  <React.StrictMode>
    <StoreProvider value={store}>
      <TokensProvider value={new Tokens(store.currentPortal)}>
        <PortalCss>
          <BrowserRouter basename={basepath}>
            <Routes>
              <Route
                path="*"
                element={(
                  <PortalRouteMapper />
                )}
              />
            </Routes>
          </BrowserRouter>
        </PortalCss>
      </TokensProvider>
    </StoreProvider>
  </React.StrictMode>
);

export default ClientPortalPage;
