/**
 * Portal app is a simple wrapper around portal-lib that supports
 * server-side rendering of tportals.
 *
 * It can be run stand alone for development and from AWS lambda
 * in production mode.
 */
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { loadableReady } from '@loadable/component';
import { configurePortalClient } from '@mcn-platform/models';
import ClientPortalPage from './ClientPortalPage';

// include CSS on the client - will get included in page template
// not needed on the SSR/server side

console.log('Loading Microcasting Portal');

Sentry.init({
  dsn: 'https://f3c4845088d86997405ce193167ba209@o4507782091505664.ingest.us.sentry.io/4507782094782464',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100%
  // while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.1,
  // If you're not already sampling the entire session,
  // change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
});

declare global {
  interface Window {
    __STATE__:any;
  }
}

/* <!-- */
// eslint-disable-next-line no-underscore-dangle
const initialState = window.__STATE__;
/* --> */
// console.log('client.tsx initialState', initialState);
// NOTE RootStore is also reading window.__STATE__ to init RootStore and setup context
const { awsConfig } = initialState;
configurePortalClient(awsConfig, initialState.snapshot).then((portalStore) => {
  console.log('client.tsx portalStore', portalStore);
  console.log('client.tsx basepath', initialState.basepath);
  // we should have org and portal from deserializing rootModel.
  loadableReady(() => {
    const container = document.getElementById('root');
    hydrateRoot(
      container!,
      (<ClientPortalPage
        store={portalStore}
        pageDefinition={initialState.pageDefinition}
        context={initialState.context}
        data={initialState.data}
        basepath={initialState.basepath}
      />),
    );
  });
});
